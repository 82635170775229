import WishListCard from '../components/wishlist-card';

const $wishListTemplate = document.querySelector('[data-wishlist-template]');
const $wishList = document.querySelector('[data-wishlist]');
const $emptyWishListMessage = document.querySelector('[data-empty-wishlist]');

const emptyMessage = `<p class="wishlist-template__empty-message">Wishlist is empty</p>`;

function checkIfWishListIsEmpty() {
    if($wishList.hasChildNodes()) {
        return;
    }
    $emptyWishListMessage.innerHTML = emptyMessage;
}

function addEventListeners() {
    window.addEventListener('wishlistItem.removed', () => {
        checkIfWishListIsEmpty();
    });
}

function renderWishList() {
    if (!window.SwymCallbacks) {
        window.SwymCallbacks = [];
    }
    window.SwymCallbacks.push(() => {
        window._swat.fetchWrtEventTypeET(products => {
            if(products.length === 0 ) {
                $emptyWishListMessage.innerHTML = emptyMessage;
                return;
            }
            products.forEach(product => {
                const price = product.pr;
                const compareAtPrice = product.op;
                let priceTemplate;

                if (compareAtPrice > price) {
                    priceTemplate = `
                    <div>
                        <p class="product-card__price product-card__price--sale">${`£${price}`}</p>
                        <s class="product-card__compare-price">
                        ${`£${compareAtPrice}`}
                        </s>
                    </div>
                    `;
                } else {
                    priceTemplate = `<span class="wishlist-card__price">${`£${price}`}</span>`;
                }

                const listTemplate = `
                    <article class="wishlist-card wishlist-template__item">
                        <img class="wishlist-card__image" src="${product.iu}"></img>
                        <div class="wishlist-card__details">
                            <h4 class="wishlist-card__title">${product.dt}</h4>
                            ${priceTemplate}
                        </div>
                        <footer class="wishlist-card__actions">
                            <button class="button button--primary button--rounded" data-add-to-cart >Add to cart</button>
                            <button class="button button--no-outline" data-remove>X Remove</button>
                        </footer>
                    </article>
                `;
                const listItem = document.createElement('li');
                listItem.classList.add('grid__cell');
                listItem.classList.add('u-1/3@tablet');
                listItem.innerHTML = listTemplate;
                $wishList.appendChild(listItem);

                // eslint-disable-next-line no-new
                new WishListCard(listItem, product);
            });
        }, window._swat.EventTypes.addToWishlist);
    });
}

if ($wishListTemplate) {
    renderWishList();
    addEventListeners();
}
